import _ from 'lodash';
import { isClientSide } from '../Config/ServerConfig';
import { isFamily, isCommunity } from '../Acl/Controls';
import { HOSTS_CONFIG } from './HostConstraint';

class Routes {
  static organisationHomePath(organisation) {
    if (isFamily(organisation)) {
      return `/families/${organisation.slug}`;
    } else if (isCommunity(organisation)) {
      return `/communities/${organisation.holding_slug}`;
    } else if (organisation.holding_slug && organisation.slug) {
      return `/${organisation.holding_slug}/${organisation.slug}`;
    } else return '/';
  }

  static canonicalHost() {
    return process.env.RAZZLE_CANONICAL_HOST;
  }

  static customQuotationPath(organisation, service) {
    let path = `/${organisation.holding_slug}/${organisation.slug}/quotation`;
    if (service) {
      path = `${path}/${service.id}`;
    }
    return path;
  }

  static cguPath() {
    return '/conditions-generales-d-utilisation';
  }

  static cguUrl() {
    return `${this.cguPath()}`;
  }

  static mentionPath() {
    return '/mentions-legales';
  }

  static mentionUrl() {
    return `${this.mentionPath()}`;
  }

  static privacyPath() {
    return '/politique-de-confidentialite';
  }

  static privacyUrl() {
    return `${this.privacyPath()}`;
  }

  static currentHost() {
    return isClientSide() ? window.location.hostname : global.host;
  }

  static currentLeague() {
    const currentHost = this.currentHost();
    const hostConfig = currentHost
      ? _.find(
          HOSTS_CONFIG,
          h =>
            h.hostname ===
            currentHost.replace('-dev', '').replace('-staging', '').replace('staging.', 'www.'),
        )
      : null;
    return hostConfig ? hostConfig.league : {};
  }

  static currentNamespace() {
    const currentHost = this.currentHost();
    const hostConfig = currentHost
      ? _.find(
          HOSTS_CONFIG,
          h =>
            h.hostname ===
            currentHost.replace('-dev', '').replace('-staging', '').replace('staging.', 'www.'),
        )
      : null;
    return hostConfig ? hostConfig.league.namespace : 'WELLO';
  }

  static currentFavicon() {
    const currentLeague = this.currentLeague();
    return currentLeague?.favicon || '/favicon.ico';
  }

  static currentFaviconNotif() {
    const currentLeague = this.currentLeague();
    return currentLeague?.notif || '/faviconnotif.ico';
  }

  static getOrganisationSlug(url) {
    const slug = url.replace(
      /^\/service-a-la-personne\/([^/]+).*|^\/association\/([^/]+).*|^\/soins-infirmier-a-domicile\/([^/]+).*|^\/organisations\/([^/]+).*/,
      '$1$2$3$4$5',
    );
    if (slug && slug !== url) return slug;
    return null;
  }

  static getOrganisationSlugFromOldPath(url) {
    const slug = url.replace(/^\/association-repit\/([^/]+).*/, '$1');
    if (slug && slug !== url) return slug;
    return null;
  }

  static getSignInRedirectionLink(currentUser, showCarenotebookDialog) {
    if (!currentUser.first_name && !currentUser.last_name)
      // Holding admin reset its password and did not sign up with invitation email
      return `/families/${currentUser?.family?.slug}/users/setup/info`;
    if (showCarenotebookDialog) return `/families/${currentUser?.family?.slug}?dialog=carenotebook`;
    return `/families/${currentUser?.family?.slug}`;
  }
}

export default Routes;
